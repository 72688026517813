import React, { CSSProperties, LegacyRef } from 'react'
import classNames from 'classnames'
import { ISectionAnticipatedRegulationTypeFields } from 'src/types/generated/contentful'
import ClosedBackersAmount from '@/components/common/CampaignCard/ClosedBackersAmount'
import SellingFast from '@/components/common/CampaignCard/SellingFast'
import { useExperimentContext } from '@/experimentation'
import { Campaign } from '@/services/api/campaign'
import useCampaign from '@/services/hooks/useCampaign'
import useCampaignAssets from '@/services/hooks/useCampaignAssets'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import CampaignDescription from './CampaignDescription'
import CampaignHeading from './CampaignHeading'
import CampaignTags from './CampaignTags'
import AmountRaised from './InvestComponents/AmountRaised'
import Backers from './InvestComponents/Backers'
import Buffer from './InvestComponents/Buffer'
import CampaignDisclaimer from './InvestComponents/CampaignDisclaimer'
import InvestButton from './InvestComponents/InvestButton'
import InvestmentDetails from './InvestComponents/InvestmentDetails'
import MinInvestment from './InvestComponents/MinInvestment'
import PriorRaise from './InvestComponents/PriorRaise/PriorRaise'
import ProgressBar from './InvestComponents/ProgressBar'
import TimeRemaining from './InvestComponents/TimeRemaining'

interface Props {
  anticipatedRegulation?: ISectionAnticipatedRegulationTypeFields
  campaign: Campaign
  className?: string
  investButtonWrapperRef?: LegacyRef<HTMLDivElement>
  sectionName: 'body' | 'sidebar' | 'hero'
  style?: CSSProperties
  theme?: 'dark' | 'light'
}

const InvestCard: React.FC<Props> = ({
  anticipatedRegulation,
  campaign,
  className,
  investButtonWrapperRef,
  sectionName,
  style = {},
  theme = 'dark',
}) => {
  const { campaign: resolvedCampaign = campaign } = useCampaign(campaign.slug)
  const { campaignAssets } = useCampaignAssets(campaign.slug)
  const siteAssets = useSiteAssets()
  const { getFeatureValue } = useExperimentContext()
  const isButtonTextWhite =
    theme === 'dark' || getFeatureValue('invest_button_white_text', false)

  const { status } = resolvedCampaign

  return (
    <div className={className} style={style}>
      <div
        id="offering_content"
        className="mb-6 w-full md:text-center lg:pt-6 2lg:pt-0 2lg:text-left"
      >
        <CampaignHeading {...campaign} theme={theme} />

        <CampaignTags
          tags={campaignAssets?.tags}
          className="hide-scrollbar mt-5 overflow-auto whitespace-nowrap 2lg:hidden"
        />

        <CampaignDescription
          document={campaignAssets?.shortDescription}
          className={classNames('mt-5', {
            'text-white': theme === 'light',
          })}
        />
      </div>

      {status === 'currently_funding' && (
        <>
          <ProgressBar campaign={resolvedCampaign} theme={theme} />

          <div
            className={classNames({
              'mb-6 mt-2': true,
              'text-core-gray-950': theme === 'dark',
              'text-white': theme === 'light',
            })}
          >
            <AmountRaised campaign={resolvedCampaign} />

            <Backers campaign={resolvedCampaign} className="mt-4" />
            {!siteAssets?.hideTimeRemaining && (
              <TimeRemaining campaign={resolvedCampaign} />
            )}
          </div>
        </>
      )}

      {status === 'testing_the_waters' && (
        <div
          className={classNames({
            'mb-6 mt-2': true,
            'text-core-gray-950': theme === 'dark',
            'text-white': theme === 'light',
          })}
        >
          <Backers
            campaign={resolvedCampaign}
            className={classNames({
              'mt-4': status !== 'testing_the_waters',
            })}
          />
        </div>
      )}

      {(status === 'closed' || status === 'archived') && (
        <ClosedBackersAmount
          campaign={campaign}
          isDisclaimed
          className={classNames({
            'text-core-gray-950': theme === 'dark',
            'text-white': theme === 'light',
          })}
        />
      )}

      <Buffer
        campaign={resolvedCampaign}
        className={classNames({
          'my-3': true,
          'text-core-gray-950': theme === 'dark',
          'text-white': theme === 'light',
        })}
      />
      {!siteAssets?.hideTimeRemaining && (
        <SellingFast
          campaign={resolvedCampaign}
          className={classNames({
            'my-2': true,
            'text-core-gray-950': theme === 'dark',
            'text-white': theme === 'light',
          })}
        />
      )}

      {!siteAssets?.usePriorRaisesLayout && (
        <div ref={investButtonWrapperRef}>
          <InvestButton
            campaign={resolvedCampaign}
            className={classNames('mt-6 block w-full', {
              '!text-core-black': !isButtonTextWhite,
            })}
            sectionName={sectionName}
            color="oxide"
          />
        </div>
      )}
      {status === 'currently_funding' && !siteAssets?.usePriorRaisesLayout && (
        <div
          className={classNames({
            'text-core-gray-950': theme === 'dark',
            'text-white': theme === 'light',
          })}
        >
          <MinInvestment
            campaign={resolvedCampaign}
            className="mt-3 text-center font-light"
          />
        </div>
      )}

      {/* TODO: check these styles with campaign that has prior raises */}
      {sectionName !== 'hero' && !siteAssets?.usePriorRaisesLayout && (
        <div className="mb-2 mt-4">
          <PriorRaise
            campaign={resolvedCampaign}
            className={classNames({
              'border-t py-2 last:border-b': true,
              'border-core-gray-200 text-core-gray-950': theme === 'dark',
              'border-[rgba(255,255,255,0.5)] text-white': theme === 'light',
            })}
          />
          <InvestmentDetails
            campaign={resolvedCampaign}
            className={classNames({
              'border-t py-2 last:border-b': true,
              'border-core-gray-200 text-core-gray-950': theme === 'dark',
              'border-[rgba(255,255,255,0.5)] text-white': theme === 'light',
            })}
          />
        </div>
      )}

      <CampaignDisclaimer
        className={classNames({
          'mt-6': true,
          'text-core-gray-700': theme === 'dark',
          'text-white': theme === 'light',
        })}
        campaign={resolvedCampaign}
        anticipatedRegulation={anticipatedRegulation}
      />
    </div>
  )
}

export default InvestCard
